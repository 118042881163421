<template>
  <div class="two-factor-modal">
    <portal to="two-factor-modal">
      <div class="modal-mask" @click="closeModal"></div>
      <div class="two-factor-wrap">
        <div class="body">
          <div class="title">{{ $t('twoFactorAuth_title') }}</div>
          <div class="step-desc">{{ page.stepDesc }}</div>
          <div class="desc" v-for="(desc, idx) in page.desc" :key="idx">
            {{ desc }}
          </div>
          <template>
            <div v-show="page.step === TwoFactorStep.scanQrcode" class="qrcode">
              <img :src="qrcodeUrl" alt="" />
            </div>
            <div
              v-show="page.step === TwoFactorStep.inputCode"
              class="validate-code"
            >
              <input
                v-for="(code, idx) in codes.filter(
                  (tpmCode, tmpCodeIdx) => tmpCodeIdx < codeCenterPoint
                )"
                :key="`code${idx}`"
                type="text"
                maxlength="1"
                size="1"
                min="0"
                max="9"
                :id="`code${idx}`"
                v-model="codes[idx]"
                @input="onValidateCode($event, idx)"
                @focus="onSelectCode"
                @keypress.enter="onKeyEnter"
                @keydown.delete="onKeyBack($event, idx)"
              />
              <div class="code-gap"></div>
              <input
                v-for="(code, idx) in codes.filter(
                  (tpmCode, tmpCodeIdx) => tmpCodeIdx >= codeCenterPoint
                )"
                :key="`code${idx + codeCenterPoint}`"
                type="text"
                maxlength="1"
                size="1"
                min="0"
                max="9"
                :id="`code${idx + codeCenterPoint}`"
                v-model="codes[idx + codeCenterPoint]"
                @input="onValidateCode($event, idx + codeCenterPoint)"
                @focus="onSelectCode"
                @keypress.enter="onKeyEnter"
                @keydown.delete="onKeyBack($event, idx + codeCenterPoint)"
              />
            </div>
          </template>
        </div>
        <!-- Button -->
        <div class="tail">
          <div
            v-if="page.btns.left"
            class="btn left"
            @click="page.btns.left.callback"
          >
            {{ $t(page.btns?.left.text) }}
          </div>
          <div
            v-if="page.btns.right"
            class="btn right"
            :class="{ stop: step === TwoFactorStep.done }"
            @click="page.btns.right.callback"
          >
            {{ $t(page.btns.right.text) }}
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import crypto from '@/utils/crypto'
import { console } from '@/utils/lib'
import {
  apiPostUser2faGenerate,
  apiPostUser2faEnable,
  apiEditUser,
  apiPostUser2fa,
  apiPostUser2faDsiable,
  // apiLogout,
  apiErrorMsg
} from '@/api'

const TwoFactorStep = {
  init: 0,
  installApp: 1,
  scanQrcode: 2,
  inputCode: 3,
  done: 4,
}

export default {
  name: 'TwoFactor',
  props: {
    parent: {
      type: String,
    },
    userInfo: {
      type: Object
    },
    stopConfirm: { // 只有停用自己的2FA才會使用
      type: Boolean,
      default: false
    },
    enable: {
      type: Number, // 0 || 1
    },
    mode: {
      type: Number, // 0 || 1
    },
    token: {
      type: Object, // {refreshToken: , accessToken: }
      default: null
    }
  },
  data() {
    return {
      TwoFactorStep,
      step: TwoFactorStep.init,

      qrcodeUrl: null,
      codeCenterPoint: 3,
      codes: [null, null, null, null, null, null],

      inStopFlow: false,
    }
  },
  computed: {
    isLogin() {
      // 因為考量後續還有 使用者管理 也會需要使用 2FA,
      // 用route.path做判斷會有開在使用者管理 + 開左側Userinfo的情況,
      // 所以改成,增加 props['parent']來判斷
      return this.parent === 'login'
      // return this.$route.path.indexOf('login') > -1
    },
    isAccount() {
      return this.parent === 'account'
    },
    page() {
      const pages = [
        {
          step: TwoFactorStep.init,
          stepDesc: null,
          desc: null,
          btns: { left: null, right: null }
        },
        {
          step: TwoFactorStep.installApp,
          stepDesc: this.$t('twoFactorAuth_installApp'),
          desc: [
            this.$t('twoFactorAuth_installApp_desc1'),
            this.$t('twoFactorAuth_installApp_desc2'),
          ],
          btns: {
            left: {
              text: 'cancel',
              callback: () => this.closeModal()
            },
            right: {
              text: 'next',
              callback: () => this.goStep(TwoFactorStep.scanQrcode)
            }
          }
        },
        {
          step: TwoFactorStep.scanQrcode,
          stepDesc: this.$t('twoFactorAuth_scanQrCode'),
          desc: [this.$t('twoFactorAuth_scanQrCode_desc')],
          btns: {
            left: {
              text: 'pre',
              callback: () => this.goStep(TwoFactorStep.installApp)
            },
            right: {
              text: 'next',
              callback: () => this.goStep(TwoFactorStep.inputCode)
            }
          }
        },
        {
          step: TwoFactorStep.inputCode,
          stepDesc: this.$t('twoFactorAuth_inputCode'),
          desc: [this.$t('twoFactorAuth_inputCode_desc')],
          btns: {
            left: this.isLogin
              ? null
              : {
                  text: 'pre',
                  callback: () => this.onInputCodePrev()
                },
            right: {
              text: 'next',
              callback: () => this.onInputCodeNext()
            }
          }
        },
        {
          step: TwoFactorStep.done,
          stepDesc: this.$t('twoFactorAuth_done'),
          desc: [this.$t('twoFactorAuth_done_desc')],
          btns: {
            left: null,
            right: {
              text: 'stop',
              callback: () => this.onStopTwoFactor()
            }
          }
        }
      ]
      const page = pages.find((content) => this.step === content.step)

      // console.log('page:', page)
      return page
    },
  },
  async mounted() {
    console.set(true)
    this.initStep()
  },
  methods: {
    ...mapActions(['logout', 'getUser']),
    closeModal() {
      this.$emit('closemodal')
    },
    initStep() {
      // console.log(`[initStep] isLogin, enable, mode:`, this.isLogin, this.enable, this.mode)
      if (this.isLogin) {
        // console.log(`[initStep] Login`)
        if(!this.enable && this.mode) {
          this.goStep(TwoFactorStep.installApp)
        } else {
          this.goStep(TwoFactorStep.inputCode)
        }
      } else if (this.isAccount) {
        this.goStep(TwoFactorStep.done)
      } else {
        // console.log(`[initStep] Other`)
        if (this.userInfo['2faEnabled']) {
          this.goStep(TwoFactorStep.done)
        } else {
          this.goStep(TwoFactorStep.installApp)
        }
      }
    },
    updateToken(accessToken, refreshToken) {
      // 更新 token
      this.$store.commit('setAccessToken', accessToken)
      localStorage.setItem('refreshToken', crypto.encrypt(refreshToken))
    },
    async getQrCode() {
      try {
        const res = await apiPostUser2faGenerate()

        if (res.status !== 200) {
          this.$notify({
            title: this.$t('twoFactorAuth_error_getQrCode'),
            message: `${res.data.message}`,
            type: 'error',
          })
          return
        }
        const img = res.data
        this.qrcodeUrl = `data:image/png;base64,${img['2faImage']}`
      } catch (err) {
        console.log(`[getQrCode] err:`, err)
      }
    },
    onValidateCode(event, idx) {
      const { value } = event.target

      // 檢查是否為數字
      if (isNaN(Number(value))) {
        this.$notify({
          title: this.$t('twoFactorAuth_error_inputNumber'),
          // message: `${res.data.message}`,
          type: 'warning',
        })
        this.codes[idx] = null
        return
      }

      if (event.inputType === 'insertText') {
        // 注焦下一個input
        if (idx === this.codes.length - 1) {
          // // 最後一個
          // document.querySelector(`.validate-code input#code${idx}`).blur()
          // document.querySelector(`.validate-code input#code${idx}`).focus()
          // 回到第一個
          document.querySelector(`.validate-code input#code0`).focus()
        } else if (idx < this.codes.length - 1) {
          document.querySelector(`.validate-code input#code${idx + 1}`).focus()
        }
      } else if (event.inputType === 'deleteContentBackward') {
        // 注焦前一個input
        if (idx === 0) {
          // 回到最後一個
          document
            .querySelector(`.validate-code input#code${this.codes.length - 1}`)
            .focus()
        } else if (idx > 0) {
          document.querySelector(`.validate-code input#code${idx - 1}`).focus()
        }
      }
    },
    onSelectCode(event) {
      // event.target.select();
      event.target.setSelectionRange(0, event.target.value.length)
    },
    onKeyEnter(/*event*/) {
      this.onInputCodeNext()
    },
    onKeyBack(event, idx) {
      const value = `${event.target.value}`

      if (value) {
        document.querySelector(`.validate-code input#code${idx}`).focus()
      }
      // 注焦前一個input
      else if (idx === 0) {
        // 回到最後一個
        document
          .querySelector(`.validate-code input#code${this.codes.length - 1}`)
          .focus()
      } else if (idx > 0) {
        document.querySelector(`.validate-code input#code${idx - 1}`).focus()
      }
    },
    async onSendCode(caller) {
      // validate code
      const code = this.codes.join('')
      if (code.length < this.codes.length) {
        this.$notify({
          title: this.$t('twoFactorAuth_error_fullCode'),
          type: 'warning',
        })
        return false
      }

      try {
        let res = null
        if (this.isLogin) {
          // 使用舊 token
          this.updateToken(this.token.accessToken, this.token.refreshToken)

          // 執行 2FA 驗證
          res = await apiPostUser2fa(code)
          if (!(res?.status === 204 || res?.status === 200)) {
            throw res
          }

          // 更新 token
          this.updateToken(res.data.accessToken, res.data.refreshToken)
        }

        res = await apiPostUser2faEnable(code)
        if (!(res?.status === 204 || res?.status === 200)) {
          throw res
        }

        if (this.isLogin) {
          await this.$emit('send2fa', true)
          return true
        }
        // console.log(`[${caller}.onSendCode]`)
        if (!this.inStopFlow && !this.stopConfirm) {
          this.$notify({
            title: this.isLogin ? this.$t('twoFactorAuth_validate_pass') : this.$t(`twoFactorAuth_enabled_pass`),
            // message: ``,
            type: 'success',
          })
        }
      } catch (err) {
        console.log(`[${caller}.onSendCode] err:`, err)
        const errorTitle =
          err?.status === 403
            ? this.$t('api_403')
            : this.isLogin ? this.$t('twoFactorAuth_validate_fail') : this.$t(`twoFactorAuth_enabled_fail`)

        this.$notify({
          title: errorTitle,
          // message: err.data.message,
          type: 'error',
        })

        // 清空驗證碼
        this.codes = [null, null, null, null, null, null]
        return false
      } finally {
        // 重新取得使用者列表
        if (!this.isLogin) {
          await this.getUser()
        }
      }

      return true
    },
    onLogout() {
      this.logout()
      this.$router.push('/login')
    },
    async stop2fa() {
      try {
        if (this.isAccount && !this.stopConfirm) {
          // 取消別人的 2FA
          // console.log(`[stop2fa] 取消別人的 2FA`)
          const param = {
            id: this.userInfo.id,
            '2faEnabled': 0
          }
          const res = await apiEditUser(param)
          if (!(res?.status === 200 || res?.status === 204)) {
            throw res
          }
        } else {
          // 取消自己的 2FA
          // console.log(`[stop2fa] 取消自己的 2FA`)
          const code = this.codes.join('')
          const res = await apiPostUser2faDsiable(code)
          if (!(res?.status === 200 || res?.status === 204)) {
            throw res
          }
        }

        this.$notify({
          title: this.$t(`twoFactorAuth_disabled_pass`),
          // message: ``,
          type: 'success',
        })
      } catch (err) {
        // console.log(`[stop2fa] err:`, err)
        const notify = {
          type: 'error',
          title: this.$t(`twoFactorAuth_disabled_fail`),
        }
        const msg = apiErrorMsg(err)
        if (msg) {
          notify.message = msg
        }
        this.$notify(notify)
      } finally {
        if (this.isAccount /*&& !this.stopConfirm*/) {
          this.closeModal()
        }

        // 轉跳首頁
        await this.getUser()

        // 停用(自己的2FA)要登出, 啟用不登出
        if(this.isAccount) {
          if (this.stopConfirm) {
            this.onLogout()
          }
        } else {
          this.onLogout()
        }

        if (this.inStopFlow) this.inStopFlow = false
      }
    },
    async onStopTwoFactor() {
      // console.log(`[2FA.onStopTwoFactor] isAccount, isLogin`, this.isAccount, this.isLogin)
      if (!this.isAccount && !this.isLogin) {
        // 確定是本人取消
        if (!this.inStopFlow) {
          this.inStopFlow = true
          this.goStep(TwoFactorStep.inputCode)
          return
        } else {
          this.inStopFlow = false
          await this.stop2fa()
        }
      } else if (this.isAccount) {
        if (this.stopConfirm) {
          // 確定是本人取消
          if (!this.inStopFlow) {
            this.inStopFlow = true
            this.goStep(TwoFactorStep.inputCode)
            return
          } else {
            this.inStopFlow = false
            await this.stop2fa()
          }
        } else {
          await this.stop2fa()
        }
      }
      // console.log(`[onStopTwoFactor] inStopFlow:`, this.inStopFlow)
    },
    async onInputCodePrev() {
      if (this.inStopFlow || this.stopConfirm) {
        this.goStep(TwoFactorStep.done)
      } else {
        this.goStep(TwoFactorStep.scanQrcode)
      }
    },
    async onInputCodeNext() {
      if (!this.isAccount && !this.isLogin) {
        if (this.inStopFlow) {
          await this.stop2fa()
          this.goStep(TwoFactorStep.installApp)
        } else {
          this.goStep(TwoFactorStep.done)
        }
      } else if (this.isAccount) {
        if (this.stopConfirm && await this.onSendCode('onInputCodeNext.2')) {
          await this.stop2fa()
        }
      } else {
        this.goStep(TwoFactorStep.done)
      }
    },
    async goStep(step) {
      const oldStep = this.step
      const newStep = step

      // const stepStr = Object.keys(TwoFactorStep)
      // console.log(`old, new:`, stepStr[oldStep], stepStr[newStep])

      // 呼叫API
      if (newStep === TwoFactorStep.scanQrcode) {
        this.qrcodeUrl = ''
        await this.getQrCode()
      } else if (newStep === TwoFactorStep.inputCode) {
        this.codes = [null, null, null, null, null, null]

        // auto focus on input
        document.querySelector(`.validate-code input#code0`)?.focus()
      } else if (newStep === TwoFactorStep.done) {
        if (oldStep === TwoFactorStep.inputCode) {
          if (!(await this.onSendCode('goStep'))) return
        } else {
          this.codes = [null, null, null, null, null, null]
        }
      }

      // set
      if (!(this.isLogin && newStep === TwoFactorStep.done)) {
        this.step = newStep
      }
    }
  },
  // watch: {
  //   inStopFlow() {
  //     console.log(`[W.inStopFlow]`, this.inStopFlow)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
$gap: 20px;
$modalW: 450px;
$yellow: #ffc600;
$red: #f94144;
$deepBlue: rgb(40, 41, 66); // #282942;
$qrcodeWH: 256px;
* {
  box-sizing: border-box;
}
// .two-factor-modal {}
.modal-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}
.two-factor-wrap {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $modalW;
  // height: 557px; // calc($modalW * 1.2);
  padding: $gap;
  border: 1px solid #4a5c78;
  background-color: $deepBlue;
  color: #fff;
  font-weight: 500;
  text-align: center;
  z-index: 3;

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: $gap;
      font-size: 2rem;
    }

    .step-desc {
      margin-bottom: $gap;
      font-size: 1.4rem;
      color: #ffe99f;
    }

    .desc {
      margin-bottom: $gap;
      font-size: 1.2rem;
    }

    .qrcode {
      img {
        width: $qrcodeWH;
        height: $qrcodeWH;
      }
    }

    .validate-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: $gap 0;

      .code-gap {
        width: 0.4rem;
      }

      input {
        width: 30px;
        height: calc(24px * 1.7);
        padding: 1px 2px;
        margin-right: 0.3rem;
        border-radius: 0.3rem;
        border: 0;
        font-size: 1rem;
        text-align: center;
        outline: none;

        &:last-child {
          margin-right: 0;
        }

        // 不顯示上下鍵號
        // /Chrome, Safari, Edge, Opera
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          // margin: 0;
        }
        // Firefox
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .tail {
    display: flex;
    justify-content: center;
    margin-top: $gap;

    .btn {
      width: 100px;
      line-height: 40px;
      border: 1px solid $yellow;
      border-radius: 5px;
      background-color: $deepBlue;
      color: #fff;
      font-size: 1.2rem;
      cursor: pointer;
    }
    .left {
      margin-right: $gap;
      color: $yellow;

      &:hover {
        background-color: #3d3f55;
      }
    }

    .right {
      background-color: $yellow;
      color: $deepBlue;

      &:hover {
        background-color: #ffd231;
      }

      &.stop {
        color: #fff;
        border-color: $red;
        background-color: $red;

        &:hover {
          background-color: #f95154;
        }
      }
    }
  }
}
</style>
